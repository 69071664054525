<template>
  <Section class="section--no-padding">
    <Tabs v-model="variableTabs"
      layout="simple">
      <form @submit.prevent="onSubmit()">
        <Tab title="Définition">
          <VariableStudiedSettingsSubview v-if="fetched"
            :task="task"
            :variableProps="variable"
            :notation="notation"
            :repeatables="repeatables" />
        </Tab>
        <Tab title="Niveau de saisie">
          <VariableStudiedEntryLevelSubview v-if="fetched"
            :task="task"
            :variableProps="variable"
            :notation="notation"
            :repeatables="repeatables" />
        </Tab>
      </form>
    </Tabs>
  </Section>

  <Teleport to="#view-header__teleport__single">
    <div class="view-header view-header--tasks-notations--edit">
      <div class="view-header__footer">
        <Btn class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{
            name: 'essaiExperimentalTaskVariableStudiedListChild',
            params: {
              id: $route.params.id,
              tid: $route.params.tid
            }
          }" />
        <template v-if="task">
          <h1 class="page-title">
            {{ pageTitle }} - {{ variable.designation }}</h1>
        </template>
      </div>
    </div>
  </Teleport>
</template>

<script>

import Section from '@/components/layout/Section.vue'
import Tabs from '@/components/base/Tabs.vue'
import Tab from '@/components/base/Tab.vue'
import VariableStudiedSettingsSubview from
  '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/VariableStudiedSettingsSubview.vue'
import VariableStudiedEntryLevelSubview from
  '@/views/essais/Essais/Children/ExperimentalTask/SingleSubviews/VariableStudiedEntryLevelSubview.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'VariableStudiedSubview',
  components: {
    Section,
    Tabs,
    Tab,
    VariableStudiedSettingsSubview,
    VariableStudiedEntryLevelSubview,
    Btn,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const singleRoutes = [
      'essaiExperimentalTaskVariableStudiedSettingsChild',
      'essaiExperimentalTaskVariableStudiedEntryLevelChild',
    ]

    const variableTabs = singleRoutes.findIndex((r) => r === this.$route.name)
    return {
      modal: {
        delete: false,
      },
      modalData: {},
      fetched: false,
      notation: {},
      singleRoutes,
      submit: false,
      variable: {},
      repeatables: {},
      variableTabs,
    }
  },

  emits: ['set-editable'],

  watch: {
    variableTabs() {
      this.$router.push({
        name: this.singleRoutes[this.variableTabs],
        params: {
          id: this.$route.params.id,
          tid: this.$route.params.tid,
          veid: this.$route.params.veid,
        },
      })
    },
  },

  mounted() {
    this.loadVariable(this.$route.params)
  },

  methods: {
    loadVariable(params) {
      this.fetchService.get(
        `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`,
      ).then((res) => {
        this.notation = res.data.pop()
        this.fetchVariable(params)
      })
    },

    fetchVariable(params) {
      this.fetchService.get(
        // eslint-disable-next-line
        `essai/${params.id}/evenement/${params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${params.veid}`,
      ).then(
        (variable) => {
          this.variable = variable.data
          if (this.variable.id) {
            const { id } = this.variable
            this.fetchService.get(
              // eslint-disable-next-line
              `essai/${params.id}/evenement/${params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${id}/valeur`,
            ).then((valeurs) => {
              this.repeatables.valeurs = valeurs.data.map((value) => ({
                Désignation: value.designation,
              }))
              return this.variable
            }).then(() => {
              this.fetchService.get(
                // eslint-disable-next-line
                `essai/${params.id}/evenement/${params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${id}/niveau_saisie?sort=ordre.ASC&limit=0`,
              ).then((organes) => {
                this.repeatables.organes = organes.data.map((value) => ({
                  Désignation: value.designation,
                  'Nombre d\'échantillons': value.nb_echantillons,
                }))
                this.fetched = true
              })
            })
          }
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
