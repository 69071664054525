<template>
  <div
    class="view-body"
  >
    <Section v-if="variable">
      <Container>
        <Section>
          <div class="form grid">
            <div class="tmp-col">
              <KeyValue label="Niveau de saisie" :value="variable.saisie_niveau.designation"/>
            </div>
          </div>
        </Section>

        <Section v-show="repeatables?.organes.length">
          <div class="form grid">
            <div class="col--fullwidth">
              <KeyValue label="Organes">
                <RepeatableValues
                  :labels="['Designation', 'Nombre d\'échantillons']"
                  :values="repeatables?.organes"
                  :n-cols="2"
                />
              </KeyValue>
            </div>
          </div>
        </Section>
      </Container>
    </Section>
  </div>
</template>

<script>

import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import RepeatableValues from '@/components/form/RepeatableValues.vue'

export default {
  name: 'VariableStudiedSettingsSubview',
  components: {
    KeyValue,
    RepeatableValues,
    Container,
    Section,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    variableProps: {
      type: Object,
    },
    notation: {
      type: Object,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    repeatables: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['set-editable'],

  data(props) {
    return {
      variable: props.variableProps,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
